import BVUtils from '@bv/utils';
import { TUserRoles } from '../../../types/user';

import * as singleSpa from 'single-spa';

const MF_PORTS: { [key: string]: string } = {
  '@bv/root-config': '9000',
  '@bv/styleguide': '9001',
  '@bv/utils': '9002',
  '@bv/documents-employee': '9008',
  '@bv/not-found': '9007',
  '@bv/components-old': '8000',
  '@bv/utils-old': '8001',
  '@bv/middleware-old': '8002',
  '@bv/login-old': '8003',
  '@bv/navigation-old': '8004',
  '@bv/dashboard-old': '8005',
  '@bv/test-visual-acuity-ai-old': '8006',
  '@bv/invited-old': '8007',
  '@bv/discounts-old': '8008',
  '@bv/invites-old': '8009',
  '@bv/plans-old': '8010',
  '@bv/profile-old': '8011',
  '@bv/dashboard-admin-old': '8012',
  '@bv/sales-old': '8013',
  '@bv/telemedicine-old': '8014',
  '@bv/test-ishihara-old': '8015',
  '@bv/test-visual-acuity-old': '8016',
  '@bv/triagem-old': '8017',
  '@bv/users-old': '8018',
  '@bv/optics-exchange-prescription-old': '8019',
  '@bv/doctor-exams-old': '8020',
  '@bv/company-management-old': '8021',
  '@bv/products-old': '8022',
};

type TApplication = {
  name: string;
  url: string;
  activeWhen: string[] | null;
  isAlwaysActive: boolean;
};

export function routePermissionHandler() {
  return new Promise<void>(async (resolve, reject) => {
    const decodedToken = BVUtils.token.getDecodedToken();
    const userViewType: TUserRoles | null = JSON.parse(localStorage.getItem('userViewType')) ?? null;

    // Aqui veriricamos se o usuário é root, se ele é um paciente sem vinculo empregaticio ou se ele é um usuário com vinculo empregaticio
    const statement = decodedToken.root
      ? await import(`../mock/root.statements`)
      : userViewType === 'patient'
        ? await import(`../mock/${userViewType}.statements`)
        : await import(`../mock/${decodedToken.companyType || 'benefit'}.${userViewType}.statements`);

    let applications = [...statement.default];
    let planDependentApplications = [];
    if (decodedToken?.modules && userViewType !== 'patient') {
      const planDependentStatement = await import(`../mock/plan.dependent.statements`);

      decodedToken.modules.forEach((module) => {
        const planDependentModule = planDependentStatement.default[module];
        // console.log({ planDependentModule });
        if (planDependentModule[0]) planDependentApplications = [...planDependentApplications, ...planDependentModule];
      });
    }

    applications = [...applications, ...planDependentApplications];

    console.log({ applications });

    //Processamento para montagem das urls dos microservicos, de acordo com o ambiente.
    const processedUrlApplications = applications.map((element: TApplication) => {
      const mfName: string = element.name.replace('@bv/', '');
      return {
        ...element,
        url:
          process.env.NODE_ENV === 'development'
            ? `${process.env.MF_URL}:${MF_PORTS[element.name]}/bv-${mfName}.js`
            : `${process.env.MF_URL}/mf/${mfName}/bv-${mfName}.js`,
      };
    });

    const applicationsToMount = [...processedUrlApplications];

    applicationsToMount.forEach((element: TApplication) => {
      singleSpa.registerApplication({
        name: element.name,
        app: () => System.import<singleSpa.LifeCycles>(element.url),
        activeWhen: element.isAlwaysActive ? () => true : element.activeWhen,
      });
    });

    const mountedApps = singleSpa.getAppNames();
    console.log({ mountedApps });
    resolve();
  });
}
